:root {
  --font-base: "Inter", "Arial", sans-serif;
  --font-second: "Montserrat", "Arial", sans-serif;
  --font-family: -apple-system, blinkmacsystemfont, var(--font-base), var(--font-second), roboto,
    "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --font-size-sm: 12px;
  --font-size: 14px;
  --font-size-lg: 16px;
}
