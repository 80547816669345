:root {
  --full-width: 100%;
  --full-height: 100vh;
  --container-min-width: 376px;
  --container-max-width: 1920px;
  --container-width: 1322px;
  --container-padding: 26px;
  --container-padding-mobile: 12px;
  --small-gap: 8px;
  --list-gap-normal: 16px;

  /* Modals */
  --modal-gutter-none: 0;
  --modal-gutter-small: 16px;
  --modal-gutter-middle: 24px;
  --modal-gutter-large: 32px;

  /* Products table */
  --detail-popover-height: 338px;
  --detail-popover-padding: 32px;
  --article-link-line-height: 16px;
}
