:root {
  /* Primary Blue */
  --primary-blue-1: #f4f9ff;
  --primary-blue-2: #e8f3ff;
  --primary-blue-3: #c4e1ff;
  --primary-blue-5: #4594e9;
  --primary-blue-6: #2487e3;
  --primary-blue-7: #1f7de0;
  --primary-blue-8: #0068d7;
  --primary-blue-9: #0058b5;

  /* Gray */
  --gray-1: #fafbfb;
  --gray-2: #e8e9ea;
  --gray-3: #c4c8c9;
  --gray-4: #a1a7a9;
  --gray-45: #cbcbcb;
  --gray-6: #737b7f;
  --gray-7: #5b6569;
  --gray-8: #444f54;

  /* Blue */
  --blue-1: #f9fafb;
  --blue-2: #f1f4f6;
  --blue-4: #d8e0e5;
  --blue-5: #c4d0d8;
  --blue-6: #aabbc6;
  --blue-7: #829bab;
  --blue-9: #032f4a;

  /* Orange */
  --orange-1: #fffaf7;
  --orange-2: #fff7f2;
  --orange-8: #f47d2f;
  --main-orange-5: #f9b083;
  --main-orange-8: #f57e2e;
  --main-orange-9: #e3742c;
  --corporate-orange: #f85a00;

  /* Neutral */
  --neutral-2: #e7e8e9;
  --neutral-6: #8e979c;
  --neutral-75: #42494d;
  --neutral-85: #1f3441;

  /* States */
  --states-error: #f64f00;
  --states-warning: #ed5c0e;
  --states-warning-2: #fefaed;
  --states-success: #00ba9c;
  --states-info: #3f5872;
  --states-info-table: #238dff;
  --success-default: #30ba3e;
  --warning-default: #fbbb00;
  --error-default: #f5412e;

  /* Main gray */
  --main-gray-1: #fafbfb;
  --main-gray-2: #f3f4f4;
  --main-gray-3: #e7e9ea;
  --main-gray-35: #e2e2e2;
  --main-gray-4: #d0d3d6;
  --main-gray-5: #b8bdc0;
  --main-gray-6: #8e979c;
  --main-gray-7: #59666e;
  --main-gray-85: #2b3236;
  --main-gray-9: #132530;
  --main-gray-95: #041824;
  --main-gray-10: #020709;

  /* Main blue gray */
  --main-blue-gray-1: #f9fafb;
  --main-blue-gray-3: #e5eaed;
  --main-blue-gray-4: #d8e0e5;
  --main-blue-gray-8: #4d7187;
  --main-blue-gray-9: #032f4a;

  /* Custom */
  --white: #fff;
  --black: #000;
  --dark: #131c20;
  --loading-mask: rgb(var(--white) 0.7);
  --transparent-green: rgb(var(--states-success) 0.05);
  --bright-blue: #48d3ff;
  --border-1: #d9d9d9;

  /* CTA */
  --cta-1: #fef2f0;
  --cta-2: #d32e1d;
  --cta-12: #f5412e;
}
